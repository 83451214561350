import * as Sentry from "@sentry/nextjs"
import { WebSocket } from "partysocket"
import { END, EventChannel, eventChannel } from "redux-saga"
import { call, cancelled } from "typed-redux-saga/macro"

import { takeEveryAndClose } from "@spatialsys/js/redux"
import { waitUntilExists, waitUntilTrue } from "@spatialsys/use-saga"
import { AppState, Selectors } from "@spatialsys/web/app-state"
import Config from "@spatialsys/web/config"

import { webSocketHandler } from "./web-socket-handler"

function createSocketChannel<T = string>(socket: WebSocket) {
  return eventChannel<MessageEvent<T>>((emit) => {
    socket.onmessage = (event) => {
      emit(event)
    }

    socket.onclose = () => {
      // Ensure when the websocket connection is closed, we also close the eventChannel
      emit(END)
    }

    return () => {
      socket.close()
    }
  })
}

function createWebSocketConnection(accessToken: string): Promise<WebSocket> {
  return new Promise((resolve, reject) => {
    const webSocketUrl = `${Config.WEB_SOCKET_URL}?token=${accessToken}`

    const webSocket = new WebSocket(webSocketUrl, [], {
      WebSocket,
      connectionTimeout: 1000,
      maxRetries: 10,
    })

    webSocket.onopen = function () {
      console.log("Websocket Connected 🔌")
      resolve(webSocket)
    }

    webSocket.onerror = function (error) {
      // Added this console error because Sentry is reporting a large number of errors from this callback and this will help debug during local dev
      console.error("Websocket Error", error)
      Sentry.captureException(error, {
        tags: { webSocketErrorMessage: error.error?.message ?? error.message ?? undefined },
        extra: { target: error.target },
      })
      reject(error)
    }
  })
}

/**
 * Connect to the SAPI WebSocket server
 *
 * Connects after the user logs in and automatically disconnects on logout because the entire tab is refreshed
 */
export function* webSocketSaga() {
  yield* waitUntilTrue((state: AppState) => Selectors.getAuthState(state).isLoggedIn)

  const accessToken = yield* waitUntilExists((state: AppState) => state.auth.accessToken)

  let socket: WebSocket | null = null
  let socketChannel: EventChannel<MessageEvent<string>> | null = null
  try {
    socket = yield* call(createWebSocketConnection, accessToken)

    socketChannel = createSocketChannel(socket)

    yield* takeEveryAndClose(socketChannel, webSocketHandler)
  } catch (error) {
    Sentry.captureException(error)
  } finally {
    const isCancelled = yield* cancelled()
    if (isCancelled) {
      // the finally block can run before socket or socketChannel are assigned values if the error in the function happens before the assignment
      if (socket) socket?.close()
      if (socketChannel) socketChannel?.close()
    }
  }
}
