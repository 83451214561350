import { QueryClient } from "@tanstack/react-query"
import { produce } from "immer"
import type { FixedTask } from "typed-redux-saga/macro"

import { ActionT, GetActionType, makeActionCreator } from "@spatialsys/js/redux"
import { SapiClient } from "@spatialsys/js/sapi/client"
import { IntegrationsResponse } from "@spatialsys/js/sapi/sapi/integrations"
import { UserData } from "@spatialsys/js/sapi/sapi/users"
import {
  AvatarSdkDataOnboarding,
  FeatureFlags,
  SpaceLimitsResponse,
  SpacePreviewResponse,
} from "@spatialsys/js/sapi/types"
import { ReactAlertMessage, RequestAdParams, UnityMessages } from "@spatialsys/unity/bridge"
import type { ScreenShareSetting } from "@spatialsys/web/rtc/rtc-state"
import { sapiClient } from "@spatialsys/web/sapi"
import { Storage } from "@spatialsys/web/storage"

import { AdinPlayState, initialAdinPlayState } from "./adinplay/adinplay-state"
import { AdsState, initialAdsState } from "./ads/ads-state"
import { AuthState, InitialAuthStateArgs, createInitialAuthState } from "./auth/auth-state"
import { BrowserNotSupportedReason, getBrowserSupport } from "./browser-support"
import { CanvasStateSeed, SpatialCanvasState, getInitialCanvasState } from "./canvas"
import { H5State, initialH5State } from "./h5/h5-state"
import { AppHlsState, initialHlsState } from "./hls"
import { ImaState, initialImaState } from "./ima/ima-state"
import { ModalsState, initialModalState } from "./modals"
import { RouteSeed, RouteState, getInitialRouteState } from "./route"
import { AppRtcState, initialRtcState } from "./rtc"
import { SpaceState, SpaceStateSeed, createInitialSpaceState } from "./space"
import { TokenGateState, initialTokenGateState } from "./token-gate-state"
import { UnityClientState, createInitialUnityState } from "./unity-client"

type DebugSettingsState = {
  disableAdSenseTiles: boolean
  noFrequencyLimitMidgameAds: boolean
  showBreakpointIndicator: boolean
  showLoadingSplash: boolean
}
const initialDebugSettingState: DebugSettingsState = {
  disableAdSenseTiles: false,
  noFrequencyLimitMidgameAds: false,
  showLoadingSplash: false,
  showBreakpointIndicator: process.env.NODE_ENV !== "production",
}
const createInitialDebugSettingState = (): DebugSettingsState => {
  const settingsFromStorage = Storage.fetch(Storage.DEBUG_SETTINGS, initialDebugSettingState, { raw: false })
  return { ...initialDebugSettingState, ...settingsFromStorage }
}

export interface AppState {
  adinPlay: AdinPlayState
  ads: AdsState
  alertFromUnity: ReactAlertMessage | null
  auth: AuthState
  authlessUserData: AuthlessUserData | null
  /** Set when the user uploads a photo during the onboarding flow */
  avatarSdkDataOnboarding: AvatarSdkDataOnboarding
  banned: BannedState
  browserUnsupportedReason: BrowserNotSupportedReason
  canvas: SpatialCanvasState
  debugSettings: DebugSettingsState
  devicePixelRatio: number
  featureFlags: FeatureFlags | null
  h5: H5State
  hasFatalException: boolean
  hls: AppHlsState
  ima: ImaState
  integrations: IntegrationsResponse | null
  isCategoryMenuOpen: boolean
  isInIframe: boolean
  mediaSettings: MediaSettings
  mobileBannerHiderCount: number
  modals: ModalsState
  openModalCount: number
  openModalsBlockingHotkeysCount: number
  pushNotificationPermissionModalType: PushNotificationPermissionModalType
  reactQueryClient: QueryClient
  route: RouteState
  rtc: AppRtcState
  sapi: SapiClient
  serviceWorkerRegistrationTask: FixedTask<ServiceWorkerRegistration | null> | null
  /**
   * If true, show a modal instructing the user to refresh the page.
   * The space is also unmounted, showing the loading splash instead.
   * This should be set whenever the client is instructed to leave the space in an iframe, for example:
   * - Getting kicked from the space
   * - Photon disconnecting
   * - etc.
   */
  showIframeBlockingModal: boolean
  space: SpaceState
  spaceLimits: SpaceLimitsResponse | null
  spacePreviewData: SpacePreviewResponse | null
  spaceToCreate: UnityMessages.CreateAndJoinRoomMessage | null
  spatialUid: string
  tokenGate: TokenGateState
  unity: UnityClientState
  user: UserData | undefined
  userAgent: string
}

const createInitialMediaSettings = (): MediaSettings => {
  // TODO fix Storage.fetch to not return union with undefined when default provided
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const itemFromStorage = Storage.fetch(Storage.SPATIAL_MEDIA_SETTINGS, DefaultMediaSettingsState)!
  if (typeof itemFromStorage === "string") {
    try {
      const parsed = JSON.parse(itemFromStorage) as MediaSettings
      const keys = [
        "screenShareSettings",
        "selectedScreenShareSetting",
        "selectedVideoInput",
        "selectedAudioInput",
        "selectedPixelRatio",
      ]
      if (keys.every((key) => key in parsed)) {
        return parsed
      }
      return DefaultMediaSettingsState
    } catch (err) {
      return DefaultMediaSettingsState
    }
  }

  return itemFromStorage
}

interface InitialStateArgs {
  devicePixelRatio: number
  immediatelyOpenLogin?: boolean
  reactQueryClient: QueryClient
  spatialUid: string
  userAgent?: string
}

export const createInitialState = (
  args: InitialAuthStateArgs & InitialStateArgs & RouteSeed & SpaceStateSeed & { canvas: CanvasStateSeed }
): AppState => {
  const userAgent = args.userAgent ?? (typeof navigator !== "undefined" ? navigator.userAgent : "")
  return {
    adinPlay: initialAdinPlayState,
    ads: initialAdsState(),
    alertFromUnity: null,
    auth: createInitialAuthState(args),
    authlessUserData: null,
    avatarSdkDataOnboarding: null,
    banned: DefaultBannedState,
    browserUnsupportedReason: getBrowserSupport(userAgent),
    canvas: getInitialCanvasState(args.canvas),
    debugSettings: createInitialDebugSettingState(),
    devicePixelRatio: args.devicePixelRatio,
    featureFlags: null,
    h5: initialH5State,
    hasFatalException: false,
    hls: initialHlsState,
    mobileBannerHiderCount: 0,
    ima: initialImaState,
    integrations: null,
    isCategoryMenuOpen: false,
    /** https://stackoverflow.com/questions/326069/how-to-identify-if-a-webpage-is-being-loaded-inside-an-iframe-or-directly-into-t */
    isInIframe: typeof window !== "undefined" && window.self !== window.top,
    mediaSettings: createInitialMediaSettings(),
    modals: initialModalState(args.immediatelyOpenLogin),
    openModalCount: 0,
    openModalsBlockingHotkeysCount: 0,
    showIframeBlockingModal: false,
    reactQueryClient: args.reactQueryClient,
    pushNotificationPermissionModalType: PushNotificationPermissionModalType.None,
    route: getInitialRouteState(args),
    rtc: initialRtcState,
    sapi: sapiClient,
    spaceLimits: null,
    spacePreviewData: null,
    spaceToCreate: null,
    tokenGate: initialTokenGateState,
    space: createInitialSpaceState(args),
    spatialUid: args.spatialUid,
    unity: createInitialUnityState(),
    user: undefined,
    userAgent,
    serviceWorkerRegistrationTask: null,
  }
}

export type AuthlessUserData = {
  avatar: string
  /**
   * If is null, the user has not confirmed the authless data.
   * If is a string, it should indicate how the user confirmed the authless data.
   */
  confirmationStatus: string | null
  isModalOpen: boolean
  name: string
  /** Included if passed via the url param `avatarId` */
  rpmId?: string
}

export interface BannedState {
  bannedUntilUnixMs: number
  isBanned: boolean
}

export const DefaultBannedState: BannedState = {
  isBanned: false,
  bannedUntilUnixMs: 0,
}

/** -1 corresponds to "Auto" */
export type PixelRatio = -1 | 0.5 | 0.25 | 0.75 | 1
export interface MediaSettings {
  screenShareSettings: ScreenShareSetting[]
  selectedAudioInput: Omit<MediaDeviceInfo, "groupId" | "toJSON"> | null
  selectedPixelRatio: PixelRatio
  selectedScreenShareSetting: ScreenShareSetting
  selectedVideoInput: Omit<MediaDeviceInfo, "groupId" | "toJSON"> | null
}

// Depending on which action that was performed to trigger notification permission, the modal will display a different subheading to the user
export enum PushNotificationPermissionModalType {
  None,
  Default,
  FollowUser,
  HostEvent,
  LoveSpace,
  RevisitSpace,
  ViewProfile,
}

export const DefaultScreenShareSetting: ScreenShareSetting = {
  maxWidth: 1280,
  maxHeight: 720,
  maxFrameRate: 30,
}

export const DefaultMediaSettingsState: MediaSettings = {
  screenShareSettings: [],
  selectedScreenShareSetting: DefaultScreenShareSetting,
  selectedVideoInput: null,
  selectedAudioInput: null,
  selectedPixelRatio: 1, // -1: auto adjust, 1: 1x, 0.5: 0.5x.
}

export enum AppActionType {
  // Push notification permission actions
  AcceptPushNotificationPermission = "AcceptPushNotificationPermission",
  ClearSpaceToCreate = "ClearSpaceToCreate",
  CrazyGamesGameplayStart = "CGGameplayStart",
  CrazyGamesGameplayStop = "CGGAmeplayStop",
  DecrementMobileBannerHiderCount = "DecrementMobileBannerHiderCount",
  DecrementModalCount = "DecrementModalCount",
  DismissPushNotificationPermission = "DismissPushNotificationPermission",
  FocusUnity = "FocusUnity",
  /**
   * Focus Unity after a slight 0s delay. In technical terms, this focuses Unity queued as a macrotask using a Promise that
   * immediately resolves.
   * Useful for when you want to focus Unity immediately after clicking a button. It guarantees that the canvas will be focused
   * rather than the button.
   */
  FocusUnityDelayed = "FocusUnityDelayed",
  // Increment/decrement actions
  IncrementMobileBannerHiderCount = "IncrementMobileBannerHiderCount",
  IncrementModalCount = "IncrementModalCount",
  PatchAuthlessUserData = "PatchAuthlessUserData",
  RequestAd = "RequestAd",
  RequestPushNotificationPermission = "RequestPushNotificationPermission",
  // Set state actions
  SetAlertFromUnity = "SetAlertFromUnity",
  SetAuthlessUserData = "SetAuthlessUserData",
  SetAvatarSdkDataOnboarding = "SetAvatarSdkDataOnboarding",
  SetBanned = "SetBanned",
  SetCategoryMenuClosed = "SetCategoryMenuClosed",
  SetCategoryMenuOpen = "SetCategoryMenuOpen",
  SetDebugSettings = "SetDebugSettings",
  SetDevicePixelRatio = "SetDevicePixelRatio",
  SetFeatureFlags = "SetFeatureFlags",
  SetHasFatalException = "SetHasFatalException",
  SetIntegrations = "SetIntegrations",
  SetMediaSettings = "SetMediaSettings",
  SetPushNotificationPermissionModalType = "SetPushNotificationPermissionModalType",
  SetServiceWorkerRegistrationTask = "SetServiceWorkerRegistrationTask",
  SetShowIframeBlockingModal = "SetShowIframeBlockingModal",
  SetSpaceLimits = "SetSpaceLimits",
  SetSpacePreviewData = "SetSpacePreviewData",
  SetSpaceToCreate = "SetSpaceToCreate",
  SetUser = "SetUser",
  UpdatePlusMembership = "UpdatePlusMembership",
}

export type FocusUnity = ActionT<AppActionType.FocusUnity>
export type FocusUnityDelayed = ActionT<AppActionType.FocusUnityDelayed>
export type AcceptPushNotificationPermission = ActionT<AppActionType.AcceptPushNotificationPermission>
export type CGGameplayStart = ActionT<AppActionType.CrazyGamesGameplayStart>
export type CGGameplayStop = ActionT<AppActionType.CrazyGamesGameplayStop>
export type DismissPushNotificationPermission = ActionT<AppActionType.DismissPushNotificationPermission>
export type RequestAd = ActionT<AppActionType.RequestAd, RequestAdParams & { callbackId: number }>
export type RequestPushNotificationPermission = ActionT<
  AppActionType.RequestPushNotificationPermission,
  PushNotificationPermissionModalType
>
export type IncrementMobileBannerHiderCount = ActionT<AppActionType.IncrementMobileBannerHiderCount>
export type DecrementModalCount = ActionT<AppActionType.DecrementModalCount, boolean>
export type IncrementModalCount = ActionT<AppActionType.IncrementModalCount, boolean>
export type DecrementMobileBannerHiderCount = ActionT<AppActionType.DecrementMobileBannerHiderCount>
export type SetAlertFromUnity = ActionT<AppActionType.SetAlertFromUnity, ReactAlertMessage | null>
export type SetAuthlessUserData = ActionT<AppActionType.SetAuthlessUserData, AuthlessUserData>
export type PatchAuthlessUserData = ActionT<AppActionType.PatchAuthlessUserData, Partial<AuthlessUserData>>
export type SetAvatarSdkDataOnboarding = ActionT<AppActionType.SetAvatarSdkDataOnboarding, AvatarSdkDataOnboarding>
export type SetBanned = ActionT<AppActionType.SetBanned, BannedState>
export type SetCategoryMenuClosed = ActionT<AppActionType.SetCategoryMenuClosed>
export type SetCategoryMenuOpen = ActionT<AppActionType.SetCategoryMenuOpen>
export type SetDebugSettings = ActionT<AppActionType.SetDebugSettings, Partial<DebugSettingsState>>
export type SetDevicePixelRatio = ActionT<AppActionType.SetDevicePixelRatio, number>
export type SetFeatureFlags = ActionT<AppActionType.SetFeatureFlags, FeatureFlags>
export type SetSpaceLimits = ActionT<AppActionType.SetSpaceLimits, SpaceLimitsResponse>
export type SetSpacePreviewData = ActionT<AppActionType.SetSpacePreviewData, SpacePreviewResponse>
export type SetIntegrations = ActionT<AppActionType.SetIntegrations, IntegrationsResponse>
export type SetHasFatalException = ActionT<AppActionType.SetHasFatalException, boolean>
export type SetMediaSettings = ActionT<AppActionType.SetMediaSettings, Partial<MediaSettings>>
export type SetPushNotificationPermissionModalType = ActionT<
  AppActionType.SetPushNotificationPermissionModalType,
  PushNotificationPermissionModalType
>
export type SetServiceWorkerRegistrationTask = ActionT<
  AppActionType.SetServiceWorkerRegistrationTask,
  FixedTask<ServiceWorkerRegistration | null>
>
export type SetShowIframeBlockingModal = ActionT<AppActionType.SetShowIframeBlockingModal, boolean>
export type ClearSpaceToCreate = ActionT<AppActionType.ClearSpaceToCreate>
export type SetSpaceToCreate = ActionT<AppActionType.SetSpaceToCreate, UnityMessages.CreateAndJoinRoomMessage>
export type SetUser = ActionT<AppActionType.SetUser, UserData | undefined>
export type UpdatePlusMembership = ActionT<AppActionType.UpdatePlusMembership>

export const AppActions = {
  focusUnity: makeActionCreator<FocusUnity>(AppActionType.FocusUnity),
  focusUnityDelayed: makeActionCreator<FocusUnityDelayed>(AppActionType.FocusUnityDelayed),
  acceptPushNotificationPermission: makeActionCreator<AcceptPushNotificationPermission>(
    AppActionType.AcceptPushNotificationPermission
  ),
  cgGameplayStart: makeActionCreator<CGGameplayStart>(AppActionType.CrazyGamesGameplayStart),
  cgGameplayStop: makeActionCreator<CGGameplayStop>(AppActionType.CrazyGamesGameplayStop),
  dismissPushNotificationPermission: makeActionCreator<DismissPushNotificationPermission>(
    AppActionType.DismissPushNotificationPermission
  ),
  requestAd: makeActionCreator<RequestAd>(AppActionType.RequestAd),
  requestPushNotificationPermission: makeActionCreator<RequestPushNotificationPermission>(
    AppActionType.RequestPushNotificationPermission
  ),
  incrementMobileBannerHiderCount: makeActionCreator<IncrementMobileBannerHiderCount>(
    AppActionType.IncrementMobileBannerHiderCount
  ),
  decrementMobileBannerHiderCount: makeActionCreator<DecrementMobileBannerHiderCount>(
    AppActionType.DecrementMobileBannerHiderCount
  ),
  decrementModalCount: makeActionCreator<DecrementModalCount>(AppActionType.DecrementModalCount),
  incrementModalCount: makeActionCreator<IncrementModalCount>(AppActionType.IncrementModalCount),
  setAlertFromUnity: makeActionCreator<SetAlertFromUnity>(AppActionType.SetAlertFromUnity),
  setAuthlessUserData: makeActionCreator<SetAuthlessUserData>(AppActionType.SetAuthlessUserData),
  patchAuthlessUserData: makeActionCreator<PatchAuthlessUserData>(AppActionType.PatchAuthlessUserData),
  setAvatarSdkDataOnboarding: makeActionCreator<SetAvatarSdkDataOnboarding>(AppActionType.SetAvatarSdkDataOnboarding),
  setBanned: makeActionCreator<SetBanned>(AppActionType.SetBanned),
  setDebugSettings: makeActionCreator<SetDebugSettings>(AppActionType.SetDebugSettings),
  setDevicePixelRatio: makeActionCreator<SetDevicePixelRatio>(AppActionType.SetDevicePixelRatio),
  setFeatureFlags: makeActionCreator<SetFeatureFlags>(AppActionType.SetFeatureFlags),
  setSpaceLimits: makeActionCreator<SetSpaceLimits>(AppActionType.SetSpaceLimits),
  setSpacePreviewData: makeActionCreator<SetSpacePreviewData>(AppActionType.SetSpacePreviewData),
  setIntegrations: makeActionCreator<SetIntegrations>(AppActionType.SetIntegrations),
  setCategoryMenuClosed: makeActionCreator<SetCategoryMenuClosed>(AppActionType.SetCategoryMenuClosed),
  setCategoryMenuOpen: makeActionCreator<SetCategoryMenuOpen>(AppActionType.SetCategoryMenuOpen),
  setHasFatalException: makeActionCreator<SetHasFatalException>(AppActionType.SetHasFatalException),
  setMediaSettings: makeActionCreator<SetMediaSettings>(AppActionType.SetMediaSettings),
  setPushNotificationPermissionModalType: makeActionCreator<SetPushNotificationPermissionModalType>(
    AppActionType.SetPushNotificationPermissionModalType
  ),
  setServiceWorkerRegistrationTask: makeActionCreator<SetServiceWorkerRegistrationTask>(
    AppActionType.SetServiceWorkerRegistrationTask
  ),
  setShowIframeBlockingModal: makeActionCreator<SetShowIframeBlockingModal>(AppActionType.SetShowIframeBlockingModal),
  clearSpaceToCreate: makeActionCreator<ClearSpaceToCreate>(AppActionType.ClearSpaceToCreate),
  setSpaceToCreate: makeActionCreator<SetSpaceToCreate>(AppActionType.SetSpaceToCreate),

  setUser: makeActionCreator<SetUser>(AppActionType.SetUser),
  updatePlusMembership: makeActionCreator<UpdatePlusMembership>(AppActionType.UpdatePlusMembership),
} as const

export type AppAction = GetActionType<typeof AppActions>

export const appStateReducer = (state: AppState, action: AppAction): AppState => {
  switch (action.type) {
    case AppActionType.AcceptPushNotificationPermission:
    case AppActionType.DismissPushNotificationPermission:
      return produce(
        state,
        (draft) => void (draft.pushNotificationPermissionModalType = PushNotificationPermissionModalType.None)
      )
    case AppActionType.IncrementMobileBannerHiderCount:
      return produce(state, (draft) => void (draft.mobileBannerHiderCount = state.mobileBannerHiderCount + 1))
    case AppActionType.DecrementMobileBannerHiderCount:
      return produce(state, (draft) => void (draft.mobileBannerHiderCount = state.mobileBannerHiderCount - 1))
    case AppActionType.DecrementModalCount:
      return produce(state, (draft) => {
        draft.openModalCount--
        if (action.payload) draft.openModalsBlockingHotkeysCount--
      })
    case AppActionType.IncrementModalCount:
      return produce(state, (draft) => {
        draft.openModalCount++
        if (action.payload) draft.openModalsBlockingHotkeysCount++
      })
    case AppActionType.SetAlertFromUnity:
      return produce(state, (draft) => void (draft.alertFromUnity = action.payload))
    case AppActionType.SetAuthlessUserData:
      return produce(state, (draft) => void (draft.authlessUserData = action.payload))
    case AppActionType.PatchAuthlessUserData:
      return produce(state, (draft) => {
        if (state.authlessUserData) {
          draft.authlessUserData = {
            ...state.authlessUserData,
            ...action.payload,
          }
        }
      })
    case AppActionType.SetAvatarSdkDataOnboarding:
      return produce(state, (draft) => void (draft.avatarSdkDataOnboarding = action.payload))
    case AppActionType.SetBanned:
      return produce(state, (draft) => void (draft.banned = action.payload))
    case AppActionType.SetCategoryMenuClosed:
      return produce(state, (draft) => void (draft.isCategoryMenuOpen = false))
    case AppActionType.SetCategoryMenuOpen:
      return produce(state, (draft) => void (draft.isCategoryMenuOpen = true))
    case AppActionType.SetPushNotificationPermissionModalType:
      return produce(state, (draft) => void (draft.pushNotificationPermissionModalType = action.payload))
    case AppActionType.SetServiceWorkerRegistrationTask:
      return produce(state, (draft) => void (draft.serviceWorkerRegistrationTask = action.payload))
    case AppActionType.SetShowIframeBlockingModal:
      return produce(state, (draft) => void (draft.showIframeBlockingModal = action.payload))
    case AppActionType.ClearSpaceToCreate:
      return produce(state, (draft) => void (draft.spaceToCreate = null))
    case AppActionType.SetSpaceToCreate:
      return produce(state, (draft) => void (draft.spaceToCreate = action.payload))
    case AppActionType.SetDebugSettings:
      // Not ideal to have side effect, but much easier to write like this
      Storage.setItem(Storage.DEBUG_SETTINGS, JSON.stringify({ ...state.debugSettings, ...action.payload }))
      return produce(state, (draft) => void (draft.debugSettings = { ...state.debugSettings, ...action.payload }))
    case AppActionType.SetDevicePixelRatio:
      return produce(state, (draft) => void (draft.devicePixelRatio = action.payload))
    case AppActionType.SetFeatureFlags:
      return produce(state, (draft) => void (draft.featureFlags = action.payload))
    case AppActionType.SetSpaceLimits:
      return produce(state, (draft) => void (draft.spaceLimits = action.payload))
    case AppActionType.SetSpacePreviewData:
      return produce(state, (draft) => void (draft.spacePreviewData = action.payload))
    case AppActionType.SetHasFatalException:
      return produce(state, (draft) => void (draft.hasFatalException = action.payload))
    case AppActionType.SetUser:
      return produce(state, (draft) => void (draft.user = action.payload))
    case AppActionType.SetMediaSettings:
      return produce(state, (draft) => {
        draft.mediaSettings = {
          ...state.mediaSettings,
          ...action.payload,
        }
      })
    case AppActionType.SetIntegrations:
      return produce(state, (draft) => void (draft.integrations = action.payload))
    default:
      return state
  }
}
